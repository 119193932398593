<!--
 * @Description: 系统管理 系统角色管理 权限组件 systemRoleManagement
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-04-23 20:14:03
 * @LastEditors: zhoucheng
-->
<template>
  <div>
    <div class="but-box">
      <el-button type="primary"
                 @click="confirm">确认修改</el-button>
    </div>
    <el-row class="tableContent">
      <dt-table ref="tabRef"
                :tableColumnList="tableColumnList"
                :data="tableList"
                :map="listMap"
                :tableConfig='tableConfig'
                :paginationConfig='paginationConfig'
                @getList="getList"
                :tableFun="tableFun"
                :hidePagination="true">
        <template slot="isFirst">
          <el-table-column type="selection"></el-table-column>
        </template>
      </dt-table>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable
  },
  props: {
    roleCode: {
      default: '',
      type: String,
    },
  },
  data () {
    //这里存放数据
    return {
      tableColumnList: [
        {
          prop: 'parkId',
          label: '停车场编号',
        },
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'parkType',
          label: '停车场类型',
        },
      ],
      //
      tableList: {
        list: [],
      },
      //
      listMap: {
        pageNum: 1,
        pageSize: 15,
        chargCode: '',
        chargName: '',
        operName: '',
      },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,pager,next,jumper', total: 0 },
      tableFun: {
        'selection-change': this.handleChange,
      },
      // 角色默认的权限列表
      authorityIdList: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    roleCode (n) {
      if (n) {
        this.getRoleAuthority()
      } else {
        this.setTableCheck([])
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    if (this.roleCode) {
      this.getRoleAuthority()
    }
    this.getList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () {
  }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    confirm () {
      let parkIdList = []
      this.massDeleteList.forEach(item => {
        parkIdList.push(item.parkId)
      });
      this.$emit('confirm', this.roleCode, parkIdList)
    },

    // 获取角色所有权限
    getRoleAuthority () {
      let info = {
        roleCode: this.roleCode
      }
      this.$systemRoleManagement.queryRoleAuthority(info).then(res => {
        let authorityIdList = res.resultEntity
        this.authorityIdList = authorityIdList
        this.setTableCheck(authorityIdList)
      })
    },

    setTableCheck (authorityIdList) {
      this.$nextTick(() => {
        this.$refs.tabRef.$children[0].clearSelection()
        for (let row of this.tableList.list) {
          for (let authorityId of authorityIdList) {
            if (authorityId.parkId == row.parkId) {
              this.$refs.tabRef.$children[0].toggleRowSelection(row, true);
              break
            }
          }
        }
      })
    },

    // 获取权限
    getList () {
      let info = {
        pageNum: 1,
        pageSize: 10000000
      }
      this.$yardManagementApi
        .ParkController(info)
        .then((response) => {
          this.tableList.list = response.resultEntity.list
          this.paginationConfig.total = response.resultEntity.total
          this.setTableCheck(this.authorityIdList)
        })
    },
    //表格多选
    handleChange (val) {
      this.massDeleteList = val
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.but-box {
  margin: 20px;
}
.tableContent {
  height: 500px;
  overflow: auto;
}
/*修改滚动条样式*/
.tableContent::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  /**/
}
.tableContent::-webkit-scrollbar-track {
  background: #0a2041;
  border-radius: 2px;
}
.tableContent::-webkit-scrollbar-thumb {
  background: #0065bd;
  border-radius: 10px;
}
</style>