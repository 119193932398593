<!--
 * @Description: 系统管理 系统角色管理 菜单树组件 systemRoleManagement
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-04-23 14:40:08
 * @LastEditors: zhoucheng
-->
<template>
  <div>
    <div class="but-box">
      <el-button type="primary"
                 @click="confirm">确认修改</el-button>
    </div>
    <div class="tree-box">
      <el-tree ref="tree"
               :props="treeProps"
               show-checkbox
               :default-checked-keys="NodeList"
               default-expand-all
               :load="loadNode"
               @check="treeCheck"
               lazy
               node-key="id">
      </el-tree>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    roleCode: {
      default: '',
      type: String,
    },
  },
  data () {
    //这里存放数据
    return {
      treeDataList: [],
      dataLength: 0,
      treeProps: {
        id: 'id',
        label: 'label',
        children: 'zones',
        isLeaf: 'leaf'
      },
      checedkList: [],
      defCheckList: [],
      NodeList: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    roleCode (n) {
      if (n) {
        this.getRoleMenu()
      }
      this.defCheckList = []
      this.checedkList = []
      this.NodeList = []
      this.$refs.tree.setCheckedKeys([])
    },
    treeDataList (n) {
      if (n.length >= this.dataLength) {
        if (this.roleCode) {
          this.getRoleMenu()
        }
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    if (this.roleCode) {
      this.getRoleMenu()
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () {
  }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 请求当前角色已拥有的菜单，并设置到菜单树上
    getRoleMenu () {
      let info = {
        roleCode: this.roleCode,
      }
      this.$systemRoleManagement.queryRoleMenu(info).then(res => {
        let dataList = res.resultEntity
        let defCheckList = []
        // 设置选中的节点
        let NodeList = []
        if (dataList) {
          dataList.forEach(item => {
            defCheckList.push(item.menuId)
            let node = this.$refs.tree.getNode(item.menuId)
            if (node && node.childNodes.length == 0) {
              NodeList.push(item.menuId)
            }
            // NodeList.push(item.menuId)
          })
          this.defCheckList = defCheckList
          this.checedkList = defCheckList
        } else {
          this.defCheckList = []
        }
        this.NodeList = NodeList
      })
    },

    // 勾选取消勾选树形组件多选框
    treeCheck (checkedNodes, checkedKeys) {
      this.checedkList = [...checkedKeys.checkedKeys, ...checkedKeys.halfCheckedKeys]
    },
    confirm () {
      this.$emit("confirm", this.roleCode, this.checedkList)
    },
    // 新增请求
    addMenu (addList) {
      let info = []
      addList.forEach(item => {
        info.push({ roleCode: this.roleCode, menuId: item, })
      })
      this.$systemRoleManagement.insertListRoleMenu(info).then(() => {
        this.defCheckList = []
        this.checedkList = []
        this.NodeList = []
        this.$emit("popClose")
      }).catch(() => {
        this.defCheckList = []
        this.checedkList = []
        this.NodeList = []
        this.$emit("popClose")
      })
    },
    // 删除请求
    delMenu (delList) {
      let info = []
      delList.forEach(item => {
        info.push({
          roleCode: this.roleCode,
          menuId: item,
        })
      })
      this.$systemRoleManagement.delListRoleMenu(info).then(() => {
        this.defCheckList = []
        this.checedkList = []
        this.NodeList = []
        this.$emit("popClose")
      }).catch(() => {
        this.defCheckList = []
        this.checedkList = []
        this.NodeList = []
        this.$emit("popClose")
      })
    },

    loadNode (node, resolve) {
      let info = {}
      if (node.level > 0 && node.key) {
        info = {
          parentId: node.key
        }
        this.$systemMenuManagement.queryList(info).then((response) => {
          let dataList = response.resultEntity
          let data = []
          dataList.forEach(item => {
            data.push({ id: item.menuId, label: item.menuName })
          })
          resolve(data)
          this.treeDataList.push(...data)
        }).catch((error) => {
          console.error('获取菜单错误', error)
        })
      }
      else if (node.level == 0) {
        info = {
          parentId: ''
        }
        this.$systemMenuManagement.queryList(info).then((response) => {
          this.dataLength = response.resultEntity.total
          let dataList = response.resultEntity
          let data = []
          dataList.forEach(item => {
            if (item.parentId === '') {
              data.push({
                id: item.menuId,
                label: item.menuName,
              })
            }
          })
          resolve(data)
          this.treeDataList.push(...data)
        }).catch((error) => {
          console.error('获取菜单错误', error)
        })
      } else {
        resolve([])
      }
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.tree-box {
  height: 500px;
  overflow: auto;
}
.but-box {
  margin: 20px;
}
/*修改滚动条样式*/
.tree-box::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  /**/
}
.tree-box::-webkit-scrollbar-track {
  background: #0a2041;
  border-radius: 2px;
}
.tree-box::-webkit-scrollbar-thumb {
  background: #0065bd;
  border-radius: 10px;
}
</style>