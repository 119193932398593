<!--
 * @Description: 系统管理 系统角色管理 systemRoleManagement
 * @Author: Gechang
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: houjinduo
-->
<template>
  <div class="mainbody">
    <!--查询条件查-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true" :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px" label="角色编号">
              <el-input placeholder="请输入" v-model="searchForm.roleCode">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              class="aformlabel"
              label="角色名称"
              label-width="90px"
            >
              <el-input placeholder="请输入" v-model="searchForm.roleName">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary" @click="search">查 询</el-button>
          <el-button type="info" @click="reset" style="margin-left: 17px"
            >重 置</el-button
          >
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>角色列表</span>
        <el-row class="tableTitleButton">
          <el-button type="primary" @click="addRole">新 增</el-button>
          <el-button
            type="info"
            @click="permissionAllocation"
            style="margin-left: 17px"
            >权限分配</el-button
          >
          <el-button
            type="info"
            @click="menuAssignment"
            style="margin-left: 17px"
            >菜单分配</el-button
          >
        </el-row>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table
          :tableColumnList="tableColumnList"
          :data="userRoleList"
          :map="listMap"
          ref="tableRef"
          :paginationConfig="paginationConfig"
          :tableConfig="tableConfig"
          @getList="getList"
          :tableFun="tableFun"
        >
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="权限">
              <template slot-scope="scope">
                <el-button
                  @click="checkPermissions(scope.row)"
                  type="text"
                  size="medium"
                  >查看权限</el-button
                >
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="菜单">
              <template slot-scope="scope">
                <el-button
                  @click="checkMenu(scope.row)"
                  type="text"
                  size="medium"
                  >查看菜单</el-button
                >
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="用户">
              <template slot-scope="scope">
                <el-button
                  @click="checkUser(scope.row)"
                  type="text"
                  size="medium"
                  >查看用户</el-button
                >
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button
                    type="text"
                    size="medium"
                    @click="handleOrderUpdate(scope.row)"
                    >修改</el-button
                  >
                </el-col>
                <el-col :span="8">
                  <el-button
                    type="text"
                    size="medium"
                    @click="handleOrderDelete(scope.row)"
                    >删除</el-button
                  >
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增、修改弹出层 0为新增 1为修改 -->
    <el-dialog
      :title="this.dialogTitle"
      :visible.sync="changeVisible"
      append-to-body
      @close="popClose"
    >
      <el-form
        :model="form"
        ref="form"
        :label-position="labelPosition"
        :inline="true"
        size="small"
        :rules="rules"
      >
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="角色编码" prop="roleCode" label-width="90px">
              <el-input
                v-model="form.roleCode"
                placeholder="请输入"
                :disabled="disabled"
                class="dt-form-width"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色名称" prop="roleName" label-width="90px">
              <el-input
                v-model="form.roleName"
                placeholder="请输入"
                class="dt-form-width"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="角色状态" prop="roleStatus" label-width="90px">
              <el-select
                clearable
                v-model="form.roleStatus"
                placeholder="请选择"
                class="dt-form-width"
              >
                <el-option
                  v-for="(item, index) in roleStatusList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="父级角色"
              prop="parentRoleCode"
              label-width="90px"
            >
              <el-select
                clearable
                v-model="form.parentRoleCode"
                placeholder="请选择"
                class="dt-form-width"
              >
                <el-option
                  v-for="(item, index) in parentRoleCodeList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!--  <el-input v-model="form.parentRoleCode"
                        class="dt-form-width"
                        placeholder="请输入"></el-input> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="备注信息" prop="remark" label-width="90px">
              <el-input
                v-model="form.remark"
                class="dt-form-width"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="end">
        <el-col :span="12" class="dt-button-box">
          <el-button
            type="primary"
            @click="formSubmit('form')"
            style="margin-left: 17px; border-radius: 4px; height: 36px"
            >确 定</el-button
          >
          <el-button @click="changeVisible = false" type="info"
            >取 消</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>

    <!-- //查看用户弹出框   一个表格-->
    <el-dialog
      :title="this.dialogTitle"
      :visible.sync="dialogUserVisible"
      append-to-body
      @close="close"
    >
      <el-row style="margin-bottom: 10px">
        <el-form :inline="true" :model="userSearchForm">
          <el-col :span="12">
            <el-form-item label-width="90px" label="用户编号">
              <el-input placeholder="请输入" v-model="userSearchForm.userCode">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex">
            <el-button type="primary" @click="userSearch">查 询</el-button>
          </el-col>
        </el-form>
      </el-row>
      <el-row>
        <dt-table
          :tableColumnList="tableChildColumnList"
          :data="userRoleChildList"
          :map="listMap"
          :paginationConfig="paginationConfigIn"
          :tableConfig="tableConfig"
          @getList="getList"
          :tableFun="tableFun"
        >
        </dt-table>
      </el-row>
    </el-dialog>

    <!-- 权限分配、菜单分配弹出层    0为权限分配、1为菜单分配-->
    <el-dialog
      :title="this.dialogTitle"
      :visible.sync="distributeVisible"
      append-to-body
    >
      {{ distributeTable }}
      <el-row type="flex" justify="end">
        <el-col :span="12" class="dt-button-box">
          <el-button @click="distributeVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="confirm"
            style="margin-left: 17px; border-radius: 4px; height: 36px"
          >
            确 定</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="isShowMenuTree"
      append-to-body
      @close="close"
    >
      <menuTree :roleCode="menuTreeRoleCode" @confirm="menuTreeConfirm" />
    </el-dialog>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="isShowAuthority"
      append-to-body
    >
      <authority :roleCode="authorityRoleCode" @confirm="authorityConfirm" />
    </el-dialog>
  </div>
</template>

<script>
import dtTable from "@/components/table.vue";
import menuTree from "./components/menuTree";
import authority from "./components/authority";
import $axios from "axios";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
    menuTree,
    authority,
  },
  data() {
    //这里存放数据
    return {
      tableFun: {
        "selection-change": this.handleChange,
      },
      //分页样式
      paginationConfig: { layout: "prev,sizes,pager,next,jumper", total: 0 },
      paginationConfigIn: { layout: "prev,pager,next,jumper", total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
        chargCode: "",
        chargName: "",
        operName: "",
      },
      //表头列表
      tableColumnList: [
        {
          prop: "roleCode",
          label: "角色编号",
        },
        {
          prop: "roleName",
          label: "角色名称",
        },
        {
          prop: "roleStatus",
          label: "角色状态",
        },
      ],
      //数据列表
      userRoleList: {
        list: [],
      },
      userRoleChildList: {
        list: [],
      },
      //子列表表头
      tableChildColumnList: [
        {
          prop: "roleCode",
          label: "角色编号",
        },
        {
          prop: "userCode",
          label: "用户账号",
        },
      ],
      //搜索表单
      searchForm: {
        roleCode: "", //角色编码
        userName: "", //角色名称
      },
      pageNum: 1, //页码
      pageSize: 15, //条数
      //修改、新增表单
      form: {
        editFlag: true, //是否可编辑
        parentRoleCode: "", //父级角色编码
        roleCode: "", //角色编码
        roleName: "", //角色名称
        roleRemark: "", //备注信息
        roleStatus: "", //角色状态
        startTime: "", //开始时间
        endTime: "", //结束时间
      },
      content: "", //内容
      distributeTable: "", //权限分配、菜单分配弹出层标题
      changeVisible: false, //新增、修改弹框显示
      distributeVisible: false,
      disabled: false, //角色编号输入框是否可以编辑
      labelPosition: "left", //对齐方向
      dialogTitle: "", //弹出框名称
      changeDialogType: null, //0为新增   1为修改
      checkDialogType: false, // 0为查看权限
      dialogUserVisible: false, //查看用户弹出框
      //角色状态启用冻结框
      roleStatusList: [
        {
          label: "启用",
          value: "0",
        },
        {
          label: "冻结",
          value: "1",
        },
      ],
      // 修改弹窗父级角色下拉框
      parentRoleCodeList: [],
      massDeleteList: [], //多选框绑定的列表
      treeData: [1, 2, 3],
      //查看用户弹出框查询条件
      userSearchForm: {
        roleCode: "", //角色编码
        userCode: "", //用户编码
      },

      // 菜单树类型 0.一对多修改，1.多对多修改
      menuTreeType: 0,
      // 菜单树是否显示
      isShowMenuTree: false,
      // 菜单树的roleCode
      menuTreeRoleCode: "",

      // 权限窗口类型 0.一对多修改，1.多对多修改
      authorityType: 0,
      // 权限组件是否显示
      isShowAuthority: false,
      // 权限组件的roleCode
      authorityRoleCode: "",
      //表单验证
      rules: {
        roleCode: [
          { required: true, validator: this.checkRoleCode, trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        roleName: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            min: 3,
            max: 12,
            message: "长度在 3 到 12 个字符",
            trigger: "blur",
          },
        ],
        roleStatus: [
          { required: true, message: "请选择角色状态", trigger: "change" },
        ],
        // parentRoleCode: [
        //   { required: true, message: '请输入', trigger: 'blur' },
        //   { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        // ]
        Pager: null, //获取分页器
      },
    };
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    //获取分页器组件
    this.Pager = this.$refs.tableRef.$children[1];
    this.initialization();
  },
  //方法集合
  methods: {
    close() {
      window.location.reload();
    },
    // 菜单树确认按钮
    menuTreeConfirm(roleCode, checedkList) {
      console.log(roleCode, "roleCode");
      if (this.menuTreeType == 0) {
        // 一对多
        if (!roleCode) {
          return;
        }
        this.setMenuOneRole(roleCode, checedkList);
      } else if (this.menuTreeType == 1) {
        // 多对多
        this.setMenuMoreRole(this.massDeleteList, checedkList);
      }
    },
    // 修改菜单方法（一对多）
    async setMenuOneRole(roleCode, checedkList) {
      let delinfo = [
        {
          roleCode: roleCode,
        },
      ];
      await this.$systemRoleManagement
        .delListRoleMenu(delinfo)
        .then(() => {
          this.isShowMenuTree = false;
          this.menuTreeRoleCode = "";
        })
        .catch(() => {
          this.isShowMenuTree = false;
          this.menuTreeRoleCode = "";
          this.$message.error("操作失败！");
        });
      if (checedkList && checedkList.length !== 0) {
        let addInfo = [];
        checedkList.forEach((item) => {
          addInfo.push({
            roleCode: roleCode,
            menuId: item,
          });
        });
        await this.$systemRoleManagement
          .insertListRoleMenu(addInfo)
          .then(() => {
            this.isShowMenuTree = false;
            this.menuTreeRoleCode = "";
            this.$message({ message: "修改成功", type: "success" });
          })
          .catch(() => {
            this.isShowMenuTree = false;
            this.menuTreeRoleCode = "";
            this.$message.error("操作失败！");
          });
      } else if (checedkList === null) {
        return;
      } else {
        this.$message({ message: "修改成功", type: "success" });
      }
    },
    // 修改菜单方法（多对多）
    setMenuMoreRole(roleList, checedkList) {
      let delRequestList = [];
      roleList.forEach((item) => {
        if (item.roleCode) {
          let delinfo = [{ roleCode: item.roleCode }];
          delRequestList.push(
            this.$systemRoleManagement.delListRoleMenu(delinfo)
          );
        } else {
          this.$message.error("操作失败！");
        }
      });
      // 删除后新增角色菜单
      $axios
        .all(delRequestList)
        .then(() => {
          let addInfo = [];
          roleList.forEach((roleItem) => {
            checedkList.forEach((menuItem) => {
              addInfo.push({
                roleCode: roleItem.roleCode,
                menuId: menuItem,
              });
            });
          });
          this.$systemRoleManagement
            .insertListRoleMenu(addInfo)
            .then(() => {
              this.isShowMenuTree = false;
              this.$message({ message: "修改成功", type: "success" });
            })
            .catch(() => {
              this.isShowMenuTree = false;
              this.$message.error("操作失败！");
            });
        })
        .catch(() => {
          this.$message.error("操作失败！");
        });
    },

    //查看菜单
    checkMenu(row) {
      this.dialogTitle = "菜单修改";
      this.menuTreeType = 0;
      this.isShowMenuTree = true;
      this.menuTreeRoleCode = row.roleCode;
    },

    //菜单分配
    menuAssignment() {
      if (this.massDeleteList.length === 0) {
        this.$message("至少选择一个角色");
      } else {
        this.dialogTitle = "菜单分配";
        this.menuTreeType = 1;
        this.isShowMenuTree = true;
        this.menuTreeRoleCode = "";
      }
    },

    //查看权限
    checkPermissions(row) {
      this.dialogTitle = "权限修改";
      this.authorityType = 0;
      this.isShowAuthority = true;
      this.authorityRoleCode = row.roleCode;
    },
    // 权限修改确认按钮
    authorityConfirm(roleCode, checedkList) {
      if (this.authorityType == 0) {
        // 一对多
        if (!roleCode) {
          return;
        }
        this.setAuthorityOneRole(roleCode, checedkList);
      } else if (this.authorityType == 1) {
        // 多对多
        this.setAuthorityMoreRole(this.massDeleteList, checedkList);
      }
      this.isShowAuthority = false;
    },

    async setAuthorityOneRole(roleCode, checedkList) {
      let info = [
        {
          roleCode: roleCode,
        },
      ];
      await this.$systemRoleManagement
        .delRoleAuthority(info)
        .then(() => {})
        .catch(() => {
          this.$message.error("操作失败！");
        });
      if (checedkList && checedkList.length !== 0) {
        let addInfo = [];
        checedkList.forEach((item) => {
          addInfo.push({
            parkId: item,
            roleCode: roleCode,
          });
        });
        await this.$systemRoleManagement
          .insertRoleAuthority(addInfo)
          .then(() => {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          })
          .catch(() => {
            this.$message.error("操作失败！");
          });
      } else if (checedkList === null) {
        return;
      } else {
        this.$message({
          message: "修改成功",
          type: "success",
        });
      }
    },
    setAuthorityMoreRole(roleList, checedkList) {
      let delrequestList = [];
      roleList.forEach((item) => {
        let delInfo = [
          {
            roleCode: item.roleCode,
          },
        ];
        delrequestList.push(
          this.$systemRoleManagement.delRoleAuthority(delInfo)
        );
      });
      $axios
        .all(delrequestList)
        .then(() => {
          // 删除后新增角色权限
          let addInfo = [];
          roleList.forEach((roleItem) => {
            checedkList.forEach((parkIdItem) => {
              addInfo.push({
                roleCode: roleItem.roleCode,
                parkId: parkIdItem,
              });
            });
          });
          this.$systemRoleManagement
            .insertRoleAuthority(addInfo)
            .then(() => {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            })
            .catch(() => {
              this.$message.error("操作失败！");
            });
        })
        .catch(() => {
          this.$message.error("操作失败！");
        });
    },

    //表格多选
    handleChange(val) {
      this.massDeleteList = val;
    },

    //权限分配
    permissionAllocation() {
      if (this.massDeleteList.length == 0) {
        this.$message("至少选择一个角色");
      } else {
        this.dialogTitle = "权限分配";
        this.authorityType = 1;
        this.isShowAuthority = true;
        this.authorityRoleCode = "";
      }
    },

    //分页
    getList(change) {
      this.pageSize = change.pageSize;
      this.pageNum = change.pageNum;
      this.initialization();
    },

    //查询数据
    initialization() {
      this.searchForm["pageNum"] = this.pageNum;
      this.searchForm["pageSize"] = this.pageSize;
      this.$systemRoleManagement
        .queryListByPage(this.searchForm)
        .then((response) => {
          let list = response.resultEntity.list;
          for (let index in list) {
            let value = list[index];
            if (value.roleStatus == 0) {
              value.roleStatus = "启用";
            } else if (value.roleStatus == 1) {
              value.roleStatus = "冻结";
            }
          }
          this.userRoleList.list = list;
          this.paginationConfig.total = response.resultEntity.total;
        });
    },

    //搜索框按钮----搜索
    search() {
      this.Pager.internalCurrentPage = 1;
      this.listMap.pageNum = 1;
      this.pageNum = 1;
      this.initialization();
    },

    //搜索框按钮----重置
    reset() {
      this.searchForm = {};
      this.Pager.internalCurrentPage = 1;
      this.listMap.pageNum = 1;
      this.pageNum = 1;
      this.initialization();
    },

    //插入一条记录
    insert() {
      let info = this.form;
      this.$systemRoleManagement.roleInsert(info).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({ message: "新增成功", type: "success" });
          this.initialization();
        } else {
          this.$message.error("新增失败");
        }
      });
    },

    // 清空验证
    popClose() {
      this.$refs.form.resetFields();
    },

    //修改一条记录
    update() {
      let info = this.form;
      this.$systemRoleManagement.roleUpdate(info).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({ message: "修改成功", type: "success" });
          this.initialization();
        } else {
          this.$message.error("修改失败");
        }
      });
    },

    //修改记录
    handleOrderUpdate(row) {
      this.disabled = true;
      this.dialogTitle = "修改角色";
      this.changeVisible = true;
      this.changeDialogType = 1;
      this.form = this.$deepClone(row);
    },

    //删除记录
    handleOrderDelete(row) {
      this.$confirm("确认删除本条角色信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.form = row;
        this.$systemRoleManagement.roleDelete(this.form).then((response) => {
          if (response.resultCode == "2000") {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.initialization();
          } else {
            this.$message.error("删除失败");
          }
        });
        // 删除角色绑定权限
        this.setAuthorityOneRole(row.roleCode, null);
        // 删除角色绑定菜单
        this.setMenuOneRole(row.roleCode, null);
        // 删除角色绑定用户
        this.$systemRoleManagement
          .deleteUserRole({ roleCode: row.roleCode })
          .then(() => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
          })
          .catch(() => {
            this.$message.error("操作失败！");
          });
      });
    },

    //查看用户
    checkUser(row) {
      this.dialogTitle = "查看用户";
      this.dialogUserVisible = true;
      this.content = "我是用户列表";
      this.userSearchForm.roleCode = row.roleCode;
      let info = {
        roleCode: row.roleCode,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$systemRoleManagement.queryRoleListByPage(info).then((response) => {
        let list = response.resultEntity.list;
        this.userRoleChildList.list = list;
        this.paginationConfig.total = response.resultEntity.total;
      });
    },

    //查看弹出框查看按钮（只支持精确查询）
    userSearch() {
      this.pageNum = 1;
      this.userSearchForm["pageNum"] = this.pageNum;
      this.userSearchForm["pageSize"] = this.pageSize;
      this.$systemRoleManagement
        .queryRoleListByPage(this.userSearchForm)
        .then((response) => {
          let list = response.resultEntity.list;
          this.userRoleChildList.list = list;
          this.paginationConfig.total = response.resultEntity.total;
        });
    },

    //新增角色
    addRole() {
      this.form = {};
      this.disabled = false;
      this.dialogTitle = "新增角色";
      this.changeVisible = true;
      this.changeDialogType = 0;
    },

    //查看权限、查看用户、查看菜单的弹出框确定按钮事件
    handleClose() {},

    //新增、修改表单弹出框提交事件
    formSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.changeDialogType == 0) {
            this.insert();
          } else if (this.changeDialogType == 1) {
            this.update();
          }
          this.changeVisible = false;
        } else {
          return false;
        }
      });
    },

    //分配菜单、权限分配弹出框的确认按钮
    confirm() {
      this.distributeVisible = false;
    },
    // 校验角色id是否重复
    checkRoleCode(rule, value, callback) {
      if (this.changeDialogType === 1) {
        callback();
        return;
      }
      if (value) {
        let info = {
          roleCode: value,
          pageNum: 1,
          pageSize: 10,
        };
        this.$systemRoleManagement.queryListByPage(info).then((response) => {
          if (response.resultEntity.total > 0) {
            callback(new Error("角色编码已存在"));
          } else {
            callback();
          }
        });
      }
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
$topSelectHeight: 135px;
.mainbody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background("blockBg");
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background("blockBg");
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        display: flex;
        padding-left: 91x;
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}

.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
</style>
